import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useState } from "react";
import styled from "styled-components";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { isCaZip } from "../ComponentsAndUtils/utils";
import ActionButton from "../ComponentsAndUtils/ActionButton";
import { NavLink, useLocation } from "react-router-dom";

import {
  caVlUpdateDate,
  flVlUpdateDate,
} from "../../../Components/ConditionsSlideIn";
import SlideIn from "../../../Components/SlideIn";
import { mobileMarginVal } from "../../../styleConstants";

const Wrapper = styled.div`
  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SubNote = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const SubNoteBottom = styled.div`
  margin-top: 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const ExternalLink = styled.a`
  text-decoration: underline;
  color: black;
`;

const ResultsNotFound = () => {
  const location = useLocation();
  const [ciSlideInOpen, setCiSlideInOpen] = useState(false);

  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const zip = checkVoterInfoSubmitted.zip;
  const isCa = isCaZip(zip);

  const vlUpdateDate = isCa ? caVlUpdateDate : flVlUpdateDate;
  const stateAbbr = isCa ? "CA" : "FL";

  let slideInContents = <></>;
  if (isCa) {
    slideInContents = (
      <>
        Under special circumstances, California voters can proactively request
        that their voter information be made confidential. Verified Voice does
        not have access to confidential voter information. This means that any
        voter with confidential voter information will not be found by our
        system and cannot sign in to Verified Voice as a verified registered
        voter.
        <br />
        <br />
        It is rare for a {`voter's`} information to be confidential and only
        applies to you if you have officially requested for your voter
        information to be made confidential.
        <br />
        <br />
        You can read more about this{" "}
        <ExternalLink
          href="https://www.sos.ca.gov/registries/safe-home/laws/confidential-voter-registration-law"
          target="_blank"
        >
          here
        </ExternalLink>
        .
      </>
    );
  } else {
    slideInContents = (
      <>
        Verified Voice does not have access to Florida voter information that is
        confidential. Any voter with confidential voter information will not be
        found by our system and cannot sign in to Verified Voice as a verified
        registered voter.
        <br />
        <br />
        Voter information is not confidential for most voters. It is only made
        confidential in specific circumstances. You can read about this{" "}
        <ExternalLink
          href="https://dos.fl.gov/elections/for-voters/voter-registration/voter-information-as-a-public-record/"
          target="_blank"
        >
          here
        </ExternalLink>
        .
      </>
    );
  }

  return (
    <>
      {ciSlideInOpen && (
        <SlideIn
          close={() => {
            setCiSlideInOpen(false);
          }}
          contents={slideInContents}
        />
      )}
      <Wrapper $stopScroll={ciSlideInOpen}>
        You were not found in our {stateAbbr} voter list.
        <SubNote>(last updated on {vlUpdateDate})</SubNote>
        <br />
        Make sure the information you entered is correct. You can check your
        official, up-to-date voter information{" "}
        <NavLink
          target="_blank"
          to={
            isCa
              ? "https://registertovote.ca.gov"
              : "https://registertovoteflorida.gov/home"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        , including your zip code on file.
        <br />
        <br />
        If {`you've`} entered the correct information, you are most likely
        missing from our voter list because you were not registered to vote at
        the time of our last voter list update. In this case, you must register
        to vote to sign in to Verified Voice.
        <ButtonWrapper>
          <ActionButton
            text="REGISTER TO VOTE"
            faIconRight={faArrowRight}
            linkPath={"/signin/help/voter-update"}
            linkFrom={location.pathname}
          />
        </ButtonWrapper>
        <SubNoteBottom>
          Note that registered voters with{" "}
          <LinkButton
            onClick={() => {
              setCiSlideInOpen(true);
            }}
          >
            confidential voter information
          </LinkButton>{" "}
          will also be missing from our voter lists and cannot sign in to
          Verified Voice.
        </SubNoteBottom>
      </Wrapper>
    </>
  );
};

ResultsNotFound.propTypes = {
  phones: PropTypes.array,
  emails: PropTypes.array,
};

export default ResultsNotFound;
