import { JDN_ENUMS, STATE_ENUMS } from "./petitionList/jurisdictionEnums";

export const getReadableJd = (petition) => {
  let jdDesc = "";
  if (petition?.jurisdiction?.jdn === JDN_ENUMS.NATIONAL) {
    jdDesc = "National Petition";
  } else if (petition?.jurisdiction?.jdn === JDN_ENUMS.STATE) {
    if (petition?.jurisdiction?.state === STATE_ENUMS.ca) {
      jdDesc = "CA State Petition";
    } else if (petition?.jurisdiction?.state === STATE_ENUMS.fl) {
      jdDesc = "FL State Petition";
    }
  }

  return jdDesc;
};
