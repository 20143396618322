import { carbonFeeAndDividend } from "./carbonFeeAndDividend";
import { permanentDst } from "./permanentDst";
import { caCondorcetPrimaries } from "./caCondorcetPrimaries";

export const allPetitions = [
  // national petitions
  carbonFeeAndDividend,
  permanentDst,
  // CA state petitions
  caCondorcetPrimaries,
];
