export const JDN_ENUMS = {
  NATIONAL: "NATIONAL",
  STATE: "STATE",
  CITY: "CITY",
};

// state enums will be lower case to match how states are stored in firedb
export const STATE_ENUMS = {
  select: "select",
  ca: "ca",
  fl: "fl",
  other: "other",
};

// city enums will retain natural capitalization to match how stored in db, and represent how it is actually displayed/capitalized
export const CITY_ENUMS = {
  select: "select",
  SacramentoCA: "SacramentoCA",
  other: "other",
};

export const STATE_DICT = {
  ca: { fullName: "California" },
  fl: { fullName: "Florida" },
};

export const CITY_DICT = {
  SacramentoCA: { name: "Sacramento", state: "ca", fullName: "Sacramento, CA" },
};
