import styled from "styled-components";
import { JDN_ENUMS, STATE_ENUMS } from "./jurisdictionEnums";

const Bold = styled.span`
  font-weight: 600;
  color: black;
`;

export const caCondorcetPrimaries = {
  url: "ca-condorcet-primaries",
  fireDbPath: "caCondorcetPrimaries",
  uiTitle: "Condorcet Primaries", // used for petition tile in petition list as well as the specific petion page, and on the stance submit confirmation modal.
  tileDescription:
    "Ranked-choice Condorcet (head-to-head) voting for California primaries.",
  jurisdiction: {
    jdn: JDN_ENUMS.STATE,
    state: STATE_ENUMS.ca,
  },
  fullText: (
    <>
      Here we petition for the state of California to enact{" "}
      <Bold>ranked-choice Condorcet voting</Bold>, also known as{" "}
      <Bold>head-to-head</Bold> or <Bold>matchup voting</Bold>, in California
      primary elections.
      <br />
      <br />
      This new voting system would apply to California primary elections for the
      following offices:
      <ul>
        <li>
          <Bold>All statewide executive offices:</Bold> Governor, Lieutenant
          Governor, Secretary of State, Attorney General, State Treasurer, State
          Controller, Superintendent of Public Instruction, and Insurance
          Commissioner
        </li>
        <li>
          <Bold>Federal offices:</Bold> U.S. Senate and U.S. House of
          Representatives (for seats representing California)
        </li>
        <li>
          <Bold>State legislature:</Bold> California State Senate and California
          State Assembly
        </li>
      </ul>
      This primary system would select exactly two candidates to advance to the
      general election. {"Here's"} how it works:
      <ol>
        <li>
          <Bold>Voters rank candidates:</Bold>
          <br />
          On the ballot, voters rank the candidates in order of preference, from
          their favorite to least favorite. Voters can rank as many or as few
          candidates as they wish.
        </li>
        <li>
          <Bold>One-on-one matchups:</Bold>
          <br />
          After ballots are cast, each candidate is compared head-to-head
          against every other candidate to determine who would win in a direct
          matchup. For example:
          <ul>
            <li>
              In a matchup between Candidate A and Candidate B, ballots ranking
              Candidate A higher count as a vote for A, and those ranking
              Candidate B higher count as a vote for B.
            </li>
            <li>
              Ballots that rank only one of the two candidates count as a vote
              for the ranked candidate. Ballots that do not rank either
              candidate do not count for that matchup.
            </li>
            <li>
              The candidate with the most votes in a matchup is the winner of
              that matchup.
            </li>
          </ul>
        </li>
        <li>
          <Bold>Determining the Condorcet winner:</Bold>
          <br />
          The candidate who wins all their one-on-one matchups against other
          candidates is the <Bold>Condorcet winner</Bold> and automatically
          advances to the general election.
        </li>
        <li>
          <Bold>Selecting the second candidate to advance:</Bold>
          <br />
          Among the remaining candidates, the one who is the{" "}
          <Bold>Condorcet winner of the remaining candidates</Bold>—the
          candidate who wins all one-on-one matchups against the others—also
          advances to the general election. Once these two candidates are
          selected, the primary election is complete, and no additional
          candidates advance.
        </li>
        <li>
          <Bold>Rare cases of ties or cycles:</Bold>
          <br />
          If no <Bold>Condorcet winner</Bold> exists in the first stage—meaning
          no candidate wins all their one-on-one matchups—a fair and transparent
          tie-breaking method will be used to select the first candidate to
          advance. Similarly, if no{" "}
          <Bold>Condorcet winner of the remaining candidates</Bold> exists in
          the second stage, the same tie-breaking method will be used to select
          the second candidate to advance.
          <ul>
            <li>
              When this voting system is enacted into law, a specific
              tie-breaking method will be established as part of its
              implementation.
            </li>
            <li>
              This petition does not mandate a specific tie-breaking method but
              requires that any chosen method be fair, transparent, consistent,
              and ensure that the <Bold>Condorcet winner</Bold> and the{" "}
              <Bold>Condorcet winner of the remaining candidates</Bold> are the
              two selected candidates when they exist.
            </li>
            <li>
              When selecting the overall <Bold>Condorcet winner</Bold> in the
              first stage, if two candidates are tied down to the vote in their
              one-on-one matchup and both defeat all other candidates in
              one-on-one matchups, these two candidates will advance to the
              general election. In this case, the process concludes, as the two
              advancing candidates fulfill the requirement for the primary
              election.
            </li>
          </ul>
        </li>
      </ol>
      For the offices specified, the current{" "}
      <Bold>top-two open primary system</Bold> would be replaced with these
      proposed <Bold>ranked-choice Condorcet primaries</Bold>.
      <br />
      <br />
      This system ensures that a candidate who is preferred to all others in
      one-on-one matchups advances to the general election. It also prevents
      vote-splitting, which can occur in our current system when similar
      candidates divide support, allowing a potentially less preferred candidate
      to win.
      {/* <br />
      <br />
      1. <Bold>Voters rank candidates:</Bold>
      <br />
      On the ballot, voters rank the candidates in order of preference, from
      their favorite to least favorite. Voters can rank as many or as few
      candidates as they wish.
      <br />
      <br />
      2. <Bold>One-on-one matchups:</Bold>
      <br />
      After ballots are cast, each candidate is compared head-to-head against
      every other candidate to determine who would win in a direct matchup. For
      example:
      <ul>
        <li>
          In a matchup between Candidate A and Candidate B, ballots ranking
          Candidate A higher count as a vote for A, and those ranking Candidate
          B higher count as a vote for B.
        </li>
        <li>
          Ballots that rank only one of the two candidates count as a vote for
          the ranked candidate. Ballots that do not rank either candidate do not
          count for that matchup.
        </li>
        <li>
          The candidate with the most votes in a matchup is the winner of that
          matchup.
        </li>
      </ul> */}
    </>
  ),
};
