import { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { allPetitions } from "./petitionList/petitionList";
import { mobileMarginVal, navbarHeight } from "../../styleConstants";
import PetitionsFooter from "./PetitionsFooter/PetitionsFooter";
import SlideIn from "../../Components/SlideIn";
import { getReadableJd } from "./petitionUtils";

const Wrapper = styled.div`
  margin-bottom: 10px;

  padding-bottom: ${(props) =>
    props.$footerHeight}px; // this is the height of the contact footer including border.

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};
`;

const SelectInstructions = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  // this shifts the page content slightly up, but fixes the text showing up between the navbars during scroll.
  margin-top: -1px;

  position: sticky;
  top: ${navbarHeight + 1}px;
  background: white;
`;

const PetitionTile = styled.div`
  font-weight: 500;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 4px;
  margin-top: 12px;
  color: black;

  // to not make it too wide on desktop view.
  max-width: 814px;

  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
`;

const JdDescription = styled.div`
  margin-top: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.44);
  font-weight: 300;
`;

const SubNote = styled.div`
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.94);
  font-size: 16px;
  font-weight: 400;
`;

const BoldSec = styled.div`
  padding-top: 6px;
  font-weight: 500;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const Petitions = () => {
  const [addPetSiOpen, setAddPetSiOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState(null);

  const addPetitionSlideInContents = (
    <>
      To add your own petition to Verified Voice, email us at:
      <BoldSec>info@verifiedvoice.org</BoldSec>
      <br />
      We will review your submission and help get it on the platform. By
      contacting us, you agree to our{" "}
      <Bold>
        <Link
          to="/about/terms-of-service"
          target="_blank"
          style={{ color: "black" }}
        >
          Terms of Service
        </Link>
      </Bold>{" "}
      and{" "}
      <Bold>
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black" }}
        >
          Privacy Policy
        </Link>
      </Bold>
      , including our policies on petition submissions and communication.
    </>
  );

  return (
    <>
      {addPetSiOpen && (
        <SlideIn
          close={() => {
            setAddPetSiOpen(false);
          }}
          contents={addPetitionSlideInContents}
        />
      )}
      <SelectInstructions>
        Select a petition to view it fully and voice your opinion:
      </SelectInstructions>
      <Wrapper $footerHeight={footerHeight} $stopScroll={addPetSiOpen}>
        {/* generating each petition tile */}
        {allPetitions.map((petition) => {
          return (
            <NavLink
              key={petition.url}
              to={`/petitions/${petition.url}`}
              style={{ textDecoration: "none" }}
            >
              <PetitionTile>
                {petition.uiTitle}
                <JdDescription>{getReadableJd(petition)}</JdDescription>
                <SubNote>{petition.tileDescription}</SubNote>
              </PetitionTile>
            </NavLink>
          );
        })}
      </Wrapper>
      <PetitionsFooter
        setFooterHeight={setFooterHeight}
        setAddPetSiOpen={setAddPetSiOpen}
      />
    </>
  );
};

export default Petitions;
