import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import SinglePetitionText from "./SinglePetitionText";
import OverallOpinion from "./OverallOpinion/OverallOpinion";
import { navbarHeight, subNavBarZ } from "../../../styleConstants";
import { getReadableJd } from "../petitionUtils";

const Title = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 600;

  @media screen and (min-width: 540px) {
    font-weight: 500;
    font-size: 24px;
  }
`;

const JdDescriptor = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.64);

  // margin-top: -2px;
  // line-height: 1;

  @media screen and (min-width: 540px) {
    // margin-top: -2px;
    font-weight: 300;
    font-size: 16px;
  }
`;

const IconWrapper = styled.span`
  padding-right: 6px;
`;

const BackTopLine = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 1px;
  line-height: 30px;
  height: 30px;
  font-weight: 300;
  font-size: 14px;

  margin-bottom: 7px;
  @media screen and (min-width: 540px) {
    margin-bottom: 11px;
  }
`;

const ViewSelectorWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  line-height: 32px;
  height: 32px;
  font-weight: 300;

  display: flex;
  justify-content: center;

  margin-top: 8px;

  // only put bottom border when the selection menu is less wide than full screen width
  // 484px width (see SelectionOptions below) plus 32px margins.
  @media screen and (min-width: 518px) {
    border-bottom: 1px solid #e5e5e5;
  }

  @media screen and (min-width: 540px) {
    margin-top: 11px;
  }
`;

const SelectionOptions = styled.div`
  display: flex;
  width: 484px;
  cursor: pointer;
`;

const UnselectedView = styled.div`
  text-align: center;
  width: 50%;
  border-bottom: 2px solid #e5e5e5;
`;

const SelectedView = styled.div`
  text-align: center;
  width: 50%;
  font-weight: 500;
  border-bottom: 2px solid black;
`;

const PetitionHeaderSticky = styled.div`
  // this shifts the page content slightly up, but fixes the text showing up between the navbars during scroll.
  margin-top: -1px;

  position: sticky;
  top: ${navbarHeight + 1}px;
  background: white;

  // needs to be lass than navbar and account menu, but needs to be present to show up for overall opinion.
  z-index: ${subNavBarZ};
`;

const SinglePetition = ({ petitionInfo }) => {
  const [textViewSelected, setTextViewSelected] = useState(true);

  return (
    <>
      <PetitionHeaderSticky>
        <BackTopLine>
          <NavLink
            to="/petitions"
            style={{
              textDecoration: "none",
              color: "black",
              cursor: "pointer",
            }}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconWrapper>{" "}
            VIEW ALL PETITIONS
          </NavLink>
        </BackTopLine>
        <Title>{petitionInfo.uiTitle}</Title>
        <JdDescriptor>{getReadableJd(petitionInfo)}</JdDescriptor>
        <ViewSelectorWrapper>
          <SelectionOptions>
            {textViewSelected ? (
              <>
                <SelectedView>Petition Text</SelectedView>
                <UnselectedView
                  onClick={() => {
                    setTextViewSelected(false);
                  }}
                >
                  Overall Opinion
                </UnselectedView>
              </>
            ) : (
              <>
                <UnselectedView
                  onClick={() => {
                    setTextViewSelected(true);
                  }}
                >
                  Petition Text
                </UnselectedView>
                <SelectedView>Overall Opinion</SelectedView>
              </>
            )}
          </SelectionOptions>
        </ViewSelectorWrapper>
      </PetitionHeaderSticky>
      {textViewSelected ? (
        <SinglePetitionText petitionInfo={petitionInfo} />
      ) : (
        <OverallOpinion petitionInfo={petitionInfo} />
      )}
    </>
  );
};

SinglePetition.propTypes = {
  petitionInfo: PropTypes.object,
};

export default SinglePetition;
