import { JDN_ENUMS } from "./jurisdictionEnums";

export const permanentDst = {
  url: "permanent-dst",
  fireDbPath: "permanentDst",
  uiTitle: "Permanent DST", // used for petition tile in petition list as well as the specific petion page, and on the stance submit confirmation modal.
  tileDescription:
    "No more time change. Daylight savings time (later sunsets) made permanent all year.",
  jurisdiction: {
    jdn: JDN_ENUMS.NATIONAL,
  },
  fullText: (
    <>
      Here we petition the United States Congress to stop the twice-a-year
      practice of time change. Instead, daylight savings time (summer time with
      later sunsets) would be made permanent year around.
    </>
  ),
};
