import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { isSignInWithEmailLink } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";

import ConditionsSlideIn from "../../Components/ConditionsSlideIn";
import SignInBoxEmail from "./ComponentsAndUtils/SignInBox/SignInBoxEmail";
import SignInBoxPhone from "./ComponentsAndUtils/SignInBox/SignInBoxPhone";
import ActionButton from "./ComponentsAndUtils/ActionButton";
import { desktopMarginsCutoff, mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "44px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "24px" : "36px")};
  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const SubNote = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  margin-bottom: 40px;

  @media screen and (min-width: 540px) {
    margin-top: 6px;
  }
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const FindContactButtonWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 540px) {
    max-width: 382px;
  }
`;

const AlreadyKnowsPrompt = styled.div`
  margin-top: 32px;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  width: fit-content;
`;

const DirectSigninBack = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
`;

const DoubleButtonWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  max-width: 432px;

  @media screen and (min-width: 540px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const ButtonSpace = styled.div`
  min-width: 8px;
`;

const TermsAgreement = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    margin-top: 4px;
  }
`;

const SignInHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useSelector((state) => state.screenWidth.value);
  const useMobileMargins = screenWidth < 540;

  // navigate to petitions if we are already signed in with a selected/matched voter.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  useEffect(() => {
    if (selectedVoter) {
      navigate("/petitions");
    }
  }, [selectedVoter]);

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const [signInMethod, setSignInMethod] = useState(null);
  const [fromEmailRedirect, setFromEmailRedirect] = useState(false); // if this is true, we have to show the email sign in box to complete sign in.
  const [alreadyKnowsContact, setAlreadyKnowsContact] = useState(false);

  // in the case that we've set the recaptcha verifier but we cancelled the phone auth attempt, refresh the page once to clear window.recaptchaVerifier
  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.location.reload(false);
    }
  }, [signInMethod]);

  // set flag if we came here from an email sign-in link
  useEffect(() => {
    if (isSignInWithEmailLink(fireAuth, window.location.href)) {
      setFromEmailRedirect(true);
    } else {
      setFromEmailRedirect(false);
    }
  }, [window.location.href]);

  // either the phone or email sign in box that we show.
  let boxToShow = <></>;
  if (signInMethod === "phone") {
    boxToShow = (
      <SignInBoxPhone
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  } else if (signInMethod === "email" || fromEmailRedirect) {
    boxToShow = (
      <SignInBoxEmail
        fromEmailRedirect={fromEmailRedirect}
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  }

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        To sign in as a registered voter, you need to verify the phone number or
        email address <Bold>{"you've"} registered to vote with</Bold>.
        <SubNote>
          Only for{" "}
          <LinkButton
            onClick={() => {
              setSignInConditionsOpen(true);
            }}
          >
            these CA & FL voters
          </LinkButton>
          .
        </SubNote>
        {/* if from email redirect, we need to show the email sign in box, not this other stuff. */}
        {!alreadyKnowsContact && !fromEmailRedirect ? (
          <>
            <FindContactButtonWrapper>
              <ActionButton
                isSingleAction={false}
                text={"FIND PHONE/EMAIL ON FILE"}
                isFullWidth={true}
                linkPath={"/signin/help"}
                linkFrom={location.pathname}
                faIconRight={faArrowRight}
              />
            </FindContactButtonWrapper>
            <AlreadyKnowsPrompt
              onClick={() => {
                setAlreadyKnowsContact(true);
              }}
            >
              Already know your phone/email on file?{" "}
              <FontAwesomeIcon icon={faArrowRight} />
            </AlreadyKnowsPrompt>
          </>
        ) : (
          <>
            {!signInMethod && !fromEmailRedirect && (
              <>
                <DirectSigninBack
                  onClick={() => {
                    setAlreadyKnowsContact(false);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> CANCEL
                </DirectSigninBack>
                <DoubleButtonWrapper>
                  <ActionButton
                    isSingleAction={false}
                    text={"VERIFY PHONE*"}
                    onClick={() => {
                      setSignInMethod("phone");
                    }}
                    faIconRight={faArrowRight}
                  />
                  <ButtonSpace />
                  <ActionButton
                    isSingleAction={false}
                    text={"VERIFY EMAIL*"}
                    onClick={() => {
                      setSignInMethod("email");
                    }}
                    faIconRight={faArrowRight}
                  />
                </DoubleButtonWrapper>
                <TermsAgreement>
                  *the phone/email {"you've"} registered to vote with. By
                  signing in, you agree to our{" "}
                  <Link
                    to="/about/terms-of-service"
                    target="_blank"
                    style={{ color: "rgba(0, 0, 0, 0.5)" }}
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/about/privacy-policy"
                    target="_blank"
                    style={{ color: "rgba(0, 0, 0, 0.5)" }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </TermsAgreement>
              </>
            )}
            {(signInMethod || fromEmailRedirect) && boxToShow}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default SignInHome;
