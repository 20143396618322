import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PetitionActionBar from "./PetitionActionBar/PetitionActionBar";

const Wrapper = styled.div`
  padding-top: 12px;

  margin-bottom: ${(props) =>
    props.$actionBarHeight}px; // this is the height of sticky bottom, including the border.
  padding-bottom: 12px;

  @media screen and (min-width: 540px) {
    padding-top: 18px;
    // font-size: 18px;
  }

  // just eye-balled what is a good page width.
  // max-width: 1020px;
  max-width: 680px;

  // NOTE: This has been left commented. We are not implementing stop scroll logic on the pettion text as of now.
  // TODO: Implement scroll blocking when petition modals are open or delete the scroll logic from this file and petition action bar
  // // stop scrolling when some slide in is open
  // overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  // position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // TODO: See if a dark gray is better suited for text. may have to apply to about page and terms.
  // color: rgba(0, 0, 0, 0.95);
  // color: #37352f; // whatt chatgpt says notion uses
  // color: #191918; // notion title bar colors. also used for text in faqs
  // color: #333333;
  // color: #444444;
`;

const SinglePetitionText = ({ petitionInfo }) => {
  const [actionBarHeight, setActionBarHeight] = useState(null);

  return (
    <>
      <Wrapper $actionBarHeight={actionBarHeight}>
        {petitionInfo.fullText}
      </Wrapper>
      <PetitionActionBar
        setActionBarHeight={setActionBarHeight}
        actionBarHeight={actionBarHeight}
        petitionInfo={petitionInfo}
      />
    </>
  );
};

SinglePetitionText.propTypes = {
  petitionInfo: PropTypes.object,
};

export default SinglePetitionText;
