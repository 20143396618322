import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../../styleConstants";
import AddPetitionButton from "./AddPetitionButton";

const Wrapper = styled.div`
  // this is for mobile margin.
  width: calc(100% - ${2 * mobileMarginVal}px);

  position: fixed;
  bottom: 0;
  background: white;

  display: flex;
  justify-content: center;

  border-top: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 12px;
  padding-bottom: 12px;

  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
    padding-top: 14px;
    padding-bottom: 14px;
  }
`;

const PetitionsFooter = ({ setFooterHeight, setAddPetSiOpen }) => {
  const petitionsFooterRef = useRef(null);
  useEffect(() => {
    // setting the total height, including the 1px border
    const heightWithBorder = petitionsFooterRef.current.clientHeight + 1;
    setFooterHeight && setFooterHeight(heightWithBorder);
  }, [petitionsFooterRef]);

  return (
    <Wrapper ref={petitionsFooterRef}>
      <AddPetitionButton
        onClick={() => {
          setAddPetSiOpen(true);
        }}
      />
    </Wrapper>
  );
};

PetitionsFooter.propTypes = {
  setFooterHeight: PropTypes.func,
  setAddPetSiOpen: PropTypes.func,
};

export default PetitionsFooter;
