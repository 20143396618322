import styled from "styled-components";
import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useScreenWidthCapture } from "./CustomHooks/useScreenWidthCapture";
import { useProcessAuthChange } from "./CustomHooks/useProcessAuthChange";

import { useSelector } from "react-redux";

import Home from "./Pages/Home/Home";
import Petitions from "./Pages/Petitions/Petitions";
import About from "./Pages/About/About";
import Navbar from "./Components/Navbar/Navbar";
import MatchedVoterOverlay from "./Components/MatchedVoterOverlay/MatchedVoterOverlay";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "./styleConstants";
import { allPetitions } from "./Pages/Petitions/petitionList/petitionList";
import SinglePetition from "./Pages/Petitions/SinglePetition/SinglePetition";
import SignInHome from "./Pages/SignIn/SignInHome";
import SignInHelp from "./Pages/SignIn/SignInHelp/SignInHelp";
import SignInHelpVoterInfo from "./Pages/SignIn/SignInHelpVoterInfo/SignInHelpVoterInfo";
import SignInHelpVoterUpdate from "./Pages/SignIn/SignInHelpVoterUpdate/SignInHelpVoterUpdate";
import SignInHelpUpdateInstructions from "./Pages/SignIn/SignInHelpUpdateInstructions";
import NavigationHooks from "./Components/NavigationHooks";
import TermsOfService from "./Pages/About/TermsOfService";
import PrivacyPolicy from "./Pages/About/PrivacyPolicy";
import Policies from "./Pages/About/Policies";

const CommonWrapper = styled.div`
  font-family: "Inter", sans-serif;
  background: white;
  color: black;
  font-size: 16px;
  line-height: 1.5;
`;

const PageContentsWrapper = styled.div`
  margin-left: ${mobileMarginVal}px;
  margin-right: ${mobileMarginVal}px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    margin-left: ${desktopMarginVal}px;
    margin-right: ${desktopMarginVal}px;
  }
`;

function App() {
  // this hook creates a listener that listens for window  resize. on a resize, we dispatch and store the screen width as a redux variable.
  // this hook creates the listener on mount and handles cleanup.
  useScreenWidthCapture();
  // this hooks creates a listener that listens for any auth change (user signed in or out)
  // the listener will handle the auth change, i.e. fetch user info, set up matched voter, and fetch matched voters.
  useProcessAuthChange();

  // This logic is to determine whether or not to show the white overlay that handles voter select and voter loading.
  // ---------------------------------------------------------------------------------
  // * *** ***** *** * *** ***** *** * *** ***** *** * *** ***** *** * *** ***** *** *
  const [showWhiteOverlay, setShowWhiteOverlay] = useState(false);
  const isVoterSetUpLoading = useSelector((state) => state.userLoading.value);
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  const userInfo = useSelector((state) => state.userInfo.value);
  // The reason this is in an effect is so that we can smoothly handle the email sign in coming to the sign in page.
  useEffect(() => {
    // we only want to show the overlay if the voter set up is loading OR there is a signed in user, but no selected voter.
    if (isVoterSetUpLoading || (userInfo?.uid && !selectedVoter)) {
      setShowWhiteOverlay(true);
    } else {
      setShowWhiteOverlay(false);
    }
  }, [isVoterSetUpLoading, userInfo, selectedVoter]);
  // * *** ***** *** * *** ***** *** * *** ***** *** * *** ***** *** * *** ***** *** *
  // ---------------------------------------------------------------------------------

  return (
    <CommonWrapper>
      <Router>
        {showWhiteOverlay ? (
          <MatchedVoterOverlay />
        ) : (
          <>
            <NavigationHooks />
            <Navbar />
            <PageContentsWrapper>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signin" element={<SignInHome />} />
                <Route path="/signin/help" element={<SignInHelp />} />
                <Route
                  path="/signin/help/voter-info"
                  element={<SignInHelpVoterInfo />}
                />
                <Route
                  path="/signin/help/update-instructions"
                  element={<SignInHelpUpdateInstructions />}
                />
                <Route
                  path="/signin/help/voter-update"
                  element={<SignInHelpVoterUpdate />}
                />
                <Route path="/petitions" element={<Petitions />} />
                {allPetitions.map((petition) => (
                  <Route
                    key={petition.url}
                    path={`/petitions/${petition.url}`}
                    element={<SinglePetition petitionInfo={petition} />}
                  />
                ))}
                <Route path="/about" element={<About />} />
                <Route path="/about/policies" element={<Policies />} />
                <Route
                  path="/about/terms-of-service"
                  element={<TermsOfService />}
                />
                <Route
                  path="/about/privacy-policy"
                  element={<PrivacyPolicy />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </PageContentsWrapper>
          </>
        )}
      </Router>
    </CommonWrapper>
  );
}

export default App;
