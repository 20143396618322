import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";
import NavbarMobileButton from "./NavbarMobileButton";
import { useEffect, useRef } from "react";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../styleConstants";

const Wrapper = styled.div`
  width: 340px;
  border: 2px solid #d0d0d0;
  border-radius: 16px;
  background: white;

  font-size: 14px;
  color: #777777;

  padding: 16px;

  position: fixed;
  top: 74px;
  // this is based on the right desktop margin. change if the desktop margin changes.

  right: ${mobileMarginVal}px;
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    right: ${desktopMarginVal}px;
  }
`;

const SignOutWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 2px;
`;

const AccountMenu = ({ closeMenu, logoRef }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !logoRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, logoRef]);

  // get the user phone or email to display
  const userInfo = useSelector((state) => state.userInfo.value);
  const userContact = userInfo?.phone || userInfo?.email || "";

  // get the selected voter info.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  // we want to display the name, and city.
  const voterName = `${selectedVoter?.first || ""} ${
    selectedVoter?.last || ""
  } ${selectedVoter?.suffix || ""}`;
  const voterStateLw = (selectedVoter?.state || "").toLowerCase();
  const voterState = voterStateLw.toUpperCase();
  const voterCity = `${selectedVoter?.city || ""}, ${voterState}`; // (${voterState}-${selectedVoter?.district || ""})`;

  const stateDistrictRef = {
    ca: {
      lowerPath: "stateAssembly",
      lowerNameBuilder: "State Assembly District ",
      upperPath: "stateSenate",
      upperNameBuilder: "State Senate District ",
    },
    fl: {
      lowerPath: "stateHouse",
      lowerNameBuilder: "State House District ",
      upperPath: "stateSenate",
      upperNameBuilder: "State Senate District ",
    },
  };

  const dsts = selectedVoter?.districts;
  const dbi = stateDistrictRef?.[voterStateLw];
  const lowerChamberDstr = `${dbi?.lowerNameBuilder} ${dsts?.[dbi?.lowerPath]}`;
  const upperChamberDstr = `${dbi?.upperNameBuilder} ${dsts?.[dbi?.upperPath]}`;
  const congDistrict = `U.S. Congressional District ${voterState}-${selectedVoter?.district || ""}`;

  return (
    <Wrapper ref={wrapperRef}>
      {userContact}
      <br />
      <br />
      {voterName}
      <br />
      {voterCity}
      <br />
      <br />
      {lowerChamberDstr}
      <br />
      {upperChamberDstr}
      <br />
      {congDistrict}
      <SignOutWrapper>
        <NavbarMobileButton
          text={"SIGN OUT"}
          onClick={() => {
            signOut(fireAuth);
            closeMenu();
          }}
        />
      </SignOutWrapper>
    </Wrapper>
  );
};

AccountMenu.propTypes = {
  closeMenu: PropTypes.func,
  logoRef: PropTypes.any,
};

export default AccountMenu;
