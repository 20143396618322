import styled from "styled-components";
import { JDN_ENUMS } from "./jurisdictionEnums";

const StyledLink = styled.a`
  text-decoration: underline;
  color: black;
`;

const StyledLinkBold = styled.a`
  text-decoration: underline;
  color: black;
  font-weight: 600;
`;

export const carbonFeeAndDividend = {
  url: "carbon-fee-and-dividend",
  fireDbPath: "carbonFeeAndDividend",
  uiTitle: "Carbon Fee and Dividend", // used for petition tile in petition list as well as the specific petion page, and on the stance submit confirmation modal.
  tileDescription:
    "A carbon fee on fossil fuels, distributed as cash dividends to the American people.",
  jurisdiction: {
    jdn: JDN_ENUMS.NATIONAL,
  },
  fullText: (
    <>
      Here we petition the United States Congress to enact a national Carbon Fee
      and Dividend.
      <br />
      <br />
      This policy would tax the carbon content of fossil fuels at the point of
      extraction or import and distribute the proceeds as dividends to the
      American people.
      <br />
      <br />
      {"Citizen's"} Climate {"Lobby's"}{" "}
      <StyledLinkBold
        href="https://citizensclimatelobby.org/price-on-carbon/"
        target="_blank"
      >
        introductory video
      </StyledLinkBold>{" "}
      and{" "}
      <StyledLink
        href="https://citizensclimatelobby.org/basics-carbon-fee-dividend/"
        target="_blank"
      >
        explainer
      </StyledLink>
      <br />
      <StyledLink
        href="https://en.wikipedia.org/wiki/Carbon_fee_and_dividend"
        target="_blank"
      >
        Wikipedia article
      </StyledLink>
    </>
  ),
};
