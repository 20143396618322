import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { httpsCallable } from "firebase/functions";
import { TailSpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { fireFunctions } from "../../../../Firebase/firebase";
import Expanded from "./Expanded";
import { STATE_ENUMS } from "../../petitionList/jurisdictionEnums";

const caStateAssemblyPop = {
  1: 338325,
  2: 316701,
  3: 286731,
  4: 275970,
  5: 364089,
  6: 287920,
  7: 295672,
  8: 335803,
  9: 276645,
  10: 285568,
  11: 318857,
  12: 313681,
  13: 229099,
  14: 286367,
  15: 311591,
  16: 340336,
  17: 299616,
  18: 277231,
  19: 308765,
  20: 286283,
  21: 278687,
  22: 241832,
  23: 290660,
  24: 250024,
  25: 263256,
  26: 223840,
  27: 234339,
  28: 306976,
  29: 241470,
  30: 310065,
  31: 226394,
  32: 319521,
  33: 221538,
  34: 301906,
  35: 209020,
  36: 249647,
  37: 266736,
  38: 276257,
  39: 247410,
  40: 339118,
  41: 333668,
  42: 364672,
  43: 258293,
  44: 337520,
  45: 260370,
  46: 287830,
  47: 328089,
  48: 277658,
  49: 258696,
  50: 266568,
  51: 325359,
  52: 300104,
  53: 253797,
  54: 224382,
  55: 305757,
  56: 288609,
  57: 222064,
  58: 261668,
  59: 312977,
  60: 240198,
  61: 285591,
  62: 245523,
  63: 313044,
  64: 287414,
  65: 270240,
  66: 320106,
  67: 260404,
  68: 220232,
  69: 289973,
  70: 271220,
  71: 329072,
  72: 345855,
  73: 269845,
  74: 308761,
  75: 314902,
  76: 282927,
  77: 328502,
  78: 316407,
  79: 273963,
  80: 309881,
};

const caStateSenatePop = {
  1: 575360,
  2: 628206,
  3: 632831,
  4: 625120,
  5: 539287,
  6: 626079,
  7: 563580,
  8: 520318,
  9: 616976,
  10: 522984,
  11: 608388,
  12: 588945,
  13: 600956,
  14: 451634,
  15: 578445,
  16: 404899,
  17: 574988,
  18: 533115,
  19: 567866,
  20: 500732,
  21: 587253,
  22: 551727,
  23: 601003,
  24: 706593,
  25: 637067,
  26: 491987,
  27: 664981,
  28: 551669,
  29: 509437,
  30: 585562,
  31: 493363,
  32: 625857,
  33: 557925,
  34: 454522,
  35: 523966,
  36: 646318,
  37: 618202,
  38: 622651,
  39: 609175,
  40: 586120,
};

const LoadingWrapper = styled.div`
  padding-top: 14px;
  display: flex;

  @media screen and (min-width: 540px) {
    padding-top: 22px;
    font-size: 18px;
  }
`;

const TailSpinWrapper = styled.div`
  padding-left: 12px;
`;

// for Summary only below

const SummaryWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 12px;
  font-weight: 300;

  @media screen and (min-width: 540px) {
    padding-top: 22px;
    font-size: 18px;
  }
`;

const CaretWrapper = styled.span`
  padding-right: 20px;
`;

const Title = styled.div`
  margin-top: 4px;
  font-weight: 500;
  cursor: pointer;

  // @media screen and (min-width: 540px) {
  //   margin-top: 26px;
  // }
`;

const TitleNoClick = styled.div`
  margin-top: 18px;
  font-weight: 500;

  @media screen and (min-width: 540px) {
    margin-top: 26px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 22px;

  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    line-height: 27px;
  }
`;

const HeaderNums = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 22px;

  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    line-height: 27px;
  }
`;

const DistrictCell = styled.div`
  width: 60px;
  @media screen and (min-width: 540px) {
    width: 94px;
  }
`;

const YesNoCell = styled.div`
  width: 62px;
  padding-left: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0); // pure white to blend in. We want these cells to match expanded exactly but no border.

  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const TotalVotersCell = styled.div`
  width: calc(
    (0.2 * 100vw) + 26px
  ); // kind of hacky thing, but allows width to to be 90px when screen width is 320, and grows as screen gets wider.
  border-left: 1px solid rgba(0, 0, 0, 0); // pure white to blend in. We want these cells to match expanded exactly but no border.
  padding-left: 4px;

  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const Bold = styled.span`
  font-weight: 500;
`;

const SubNote = styled.div`
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    font-size: 16px;
  }
`;

const SubNoteBreakdown = styled.div`
  margin-top: 20px;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    font-size: 16px;
    margin-top: 26px;
  }
`;

const OverallOpinionCa = ({ fireDbPetitionPath }) => {
  const [resultsData, setResultsData] = useState(null);
  const [expandedChamber, setExpandedChamber] = useState(null);

  // fetch voterResults
  useEffect(() => {
    const getResultsBackend = httpsCallable(
      fireFunctions,
      "getStanceCountState",
    );
    getResultsBackend({
      state: STATE_ENUMS.ca,
      fireDbPetition: fireDbPetitionPath,
    }).then((results) => {
      // calculating totals for each state.
      const data = results.data;
      ["stateAssembly", "stateSenate"].forEach((chamber) => {
        // the total yes's, no's and total voters accross districts
        const chamberTotal = { YES: 0, NO: 0, TOTAL: 0 };
        const districtCount = chamber === "stateAssembly" ? 80 : 40;
        // going through each district
        for (let i = 1; i <= districtCount; i++) {
          const distrStr = i.toString();
          const districtStances = data[chamber][distrStr];
          // updating the total yes's and no's by adding this district's stances to the total.
          chamberTotal["YES"] += districtStances["YES"];
          chamberTotal["NO"] += districtStances["NO"];
          // getting the total voters in the district
          const districtPop = (
            chamber === "stateAssembly" ? caStateAssemblyPop : caStateSenatePop
          )[distrStr];
          // adding district pop to total state pop, as well as setting the district pop as a field for this district.
          chamberTotal["TOTAL"] += districtPop;
          data[chamber][distrStr]["TOTAL"] = districtPop;
        }
        data[chamber]["TOTAL"] = chamberTotal;
      });
      setResultsData(data);
    });
  }, []);

  let guts = <></>;
  if (!resultsData) {
    guts = (
      <LoadingWrapper>
        Fetching results...{" "}
        <TailSpinWrapper>
          <TailSpin color="black" height={24} width={24} />
        </TailSpinWrapper>
      </LoadingWrapper>
    );
  } else {
    if (expandedChamber) {
      guts = (
        <Expanded
          title={
            expandedChamber === "stateAssembly"
              ? "State Assembly"
              : "State Senate"
          }
          data={resultsData[expandedChamber]}
          collapse={() => {
            setExpandedChamber(null);
          }}
        />
      );
    } else {
      const caYes = resultsData["stateAssembly"]["TOTAL"]["YES"];
      const caNo = resultsData["stateAssembly"]["TOTAL"]["NO"];
      const caTotal = resultsData["stateAssembly"]["TOTAL"]["TOTAL"];

      guts = (
        <SummaryWrapper>
          <Bold>Total support (Yes)/opposition (No)</Bold>
          <SubNote>
            Includes all California voter stances entered on Verified Voice for
            this petition.
          </SubNote>
          <TitleNoClick>California Total</TitleNoClick>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>Yes</YesNoCell>
            <YesNoCell>No</YesNoCell>
            <TotalVotersCell>Total Voters</TotalVotersCell>
          </Header>
          <HeaderNums>
            <DistrictCell></DistrictCell>
            <YesNoCell>{caYes.toLocaleString()}</YesNoCell>
            <YesNoCell>{caNo.toLocaleString()}</YesNoCell>
            <TotalVotersCell>{caTotal.toLocaleString()}</TotalVotersCell>
          </HeaderNums>
          <SubNoteBreakdown>
            This count can be broken down by {"California's"} state assembly or
            state senate districts:
          </SubNoteBreakdown>
          <Title
            onClick={() => {
              setExpandedChamber("stateAssembly");
            }}
          >
            <CaretWrapper>
              <FontAwesomeIcon icon={faCaretRight} />
            </CaretWrapper>
            State Assembly
          </Title>
          <Title
            onClick={() => {
              setExpandedChamber("stateSenate");
            }}
          >
            <CaretWrapper>
              <FontAwesomeIcon icon={faCaretRight} />
            </CaretWrapper>
            State Senate
          </Title>
        </SummaryWrapper>
      );
    }
  }

  return guts;
};

OverallOpinionCa.propTypes = {
  fireDbPetitionPath: PropTypes.string,
};

export default OverallOpinionCa;
