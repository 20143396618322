import PropTypes from "prop-types";
import { JDN_ENUMS, STATE_ENUMS } from "../../petitionList/jurisdictionEnums";
import OverallOpinionNational from "./OverallOpinionNational";
import OverallOpinionCa from "./OverallOpinionCa";

const OverallOpinion = ({ petitionInfo }) => {
  const jdn = petitionInfo?.jurisdiction?.jdn;
  const state = petitionInfo?.jurisdiction?.state;
  const fireDbPath = petitionInfo?.fireDbPath;

  let guts = <></>;
  if (jdn === JDN_ENUMS.NATIONAL) {
    guts = <OverallOpinionNational fireDbPetitionPath={fireDbPath} />;
  } else if (jdn === JDN_ENUMS.STATE && state === STATE_ENUMS.ca) {
    guts = <OverallOpinionCa fireDbPetitionPath={fireDbPath} />;
  }

  return guts;
};

OverallOpinion.propTypes = {
  petitionInfo: PropTypes.object,
};

export default OverallOpinion;
