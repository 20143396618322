import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "54px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "28px" : "54px")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;

  // font-size: 18px;
`;

const Title = styled.div`
  padding-top: 4px;
  font-size: ${(props) => (props.$useMobileMargins ? "20px" : "22px")};
  font-weight: 500;
  padding-bottom: 20px;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const Policies = () => {
  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  return (
    <Wrapper $useMobileMargins={useMobileMargins}>
      <Title $useMobileMargins={useMobileMargins}>Policies</Title>
      Here are our{" "}
      <Bold>
        <Link
          to="/about/terms-of-service"
          target="_blank"
          style={{ color: "black" }}
        >
          Terms of Service
        </Link>
      </Bold>{" "}
      and{" "}
      <Bold>
        <Link
          to="/about/privacy-policy"
          target="_blank"
          style={{ color: "black" }}
        >
          Privacy Policy
        </Link>
      </Bold>
      .
      <br />
      <br />
      By using Verified Voice—including browsing our website, signing in,
      participating in petitions, communicating with us, or interacting with our
      platform in any way—you agree to these policies.
    </Wrapper>
  );
};

export default Policies;
