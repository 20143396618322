import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid black;
  text-align: center;
  color: balck;
  background: white;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;

  height: 32px;
  line-height: 32px;
  width: calc(100% - 2px);
  max-width: 480px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
  &:hover {
    background: black;
    color: white;
    border: 1px solid black;
  }
`;

const IconWrapperLeft = styled.span`
  margin-right: 6px;
`;

const AddPetitionButton = ({ onClick }) => {
  return (
    <Wrapper onClick={() => onClick()}>
      <IconWrapperLeft>
        <FontAwesomeIcon icon={faCirclePlus} />
      </IconWrapperLeft>
      ADD YOUR OWN PETITION
    </Wrapper>
  );
};

AddPetitionButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default AddPetitionButton;
