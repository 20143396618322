import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
  overlayZ,
} from "../styleConstants";

const GutsWrapper = styled.div`
  padding-left: ${mobileMarginVal}px;
  padding-right: ${mobileMarginVal}px;
  padding-top: 12px;
  padding-bottom: 16px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    padding-left: ${desktopMarginVal}px;
    padding-right: ${desktopMarginVal}px;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  // just eye-balled what is a good page width.
  max-width: 680px;
  // max-width: 70ch;
`;

const GrayBack = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus
  z-index: ${overlayZ};
  background: rgba(95, 95, 95, 0.75);
`;

const Slide = styled.div`
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: calc(100% - 18px);
  bottom: 0;

  background: white;

  animation: slide 0.5s ease;

  @keyframes slide {
    from {
      bottom: calc(-1 * (100% - 18px));
    }

    to {
      bottom: 0;
    }
  }

  // making a sideways slide in for wider screen widths.
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(min((100% - 50px), max(70%, 850px)));
    height: 100%;
    right: 0;

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    @keyframes slide {
      from {
        right: calc(-1 * min((100% - 50px), max(70%, 850px)));
      }

      to {
        right: 0;
      }
    }
  }
`;

const X = styled.div`
  font-size: 26px;
  color: black;
  // margin-bottom: 16px;
`;

const ClickableX = styled.span`
  cursor: pointer;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;

  padding-top: 16px;
  padding-bottom: 10px;

  border-bottom: 1px solid #e5e5e5;

  margin-left: ${mobileMarginVal}px;
  margin-right: ${mobileMarginVal}px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    margin-left: ${desktopMarginVal}px;
    margin-right: ${desktopMarginVal}px;
    padding-bottom: 14px;
  }
`;

const SlideIn = ({ close, contents }) => {
  return (
    <GrayBack>
      <Slide>
        <StickyHeader>
          <X>
            <ClickableX>
              <FontAwesomeIcon icon={faXmark} onClick={close} />
            </ClickableX>
          </X>
        </StickyHeader>
        <GutsWrapper>{contents}</GutsWrapper>
      </Slide>
    </GrayBack>
  );
};

SlideIn.propTypes = {
  close: PropTypes.func,
  contents: PropTypes.element,
};

export default SlideIn;
