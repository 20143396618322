import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { fireDb, fireFunctions } from "../../../../Firebase/firebase";
import { doc, getDoc } from "firebase/firestore/lite";
import { setMatchedSelectedVoter } from "../../../../Redux/matchedSelectedVoterSlice";
import NavbarMobileButton from "../../../../Components/Navbar/NavbarMobileButton";
import PetitionButton from "./PetitionButton";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
  overlayZ,
} from "../../../../styleConstants";
import ConfirmationOverlay from "./ConfirmationOverlay";
import { JDN_ENUMS, STATE_DICT } from "../../petitionList/jurisdictionEnums";

const StickyBottom = styled.div`
  padding-top: 6px;
  text-align: center;

  position: fixed;
  bottom: 0;
  background: white;
  padding-bottom: 6px;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;

  // the desktop height of everything on single line when signed in.
  min-height: 100px;

  // this is for mobile margin.
  width: calc(100% - ${2 * mobileMarginVal}px);

  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const SignatoryName = styled.div`
  color: rgba(0, 0, 0, 0.25);
`;

const BoldDeclaration = styled.div`
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: center;
`;

const ButtonSpace = styled.div`
  min-width: 6px;
`;

const SignInInstructions = styled.div`
  padding-top: 14px;
  padding-bottom: 8px;
`;

const SignInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DisableInteraction = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - ${(props) => props.$actionBarHeight}px);
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus to gray out/disable them
  z-index: ${overlayZ};
  background: rgba(255, 255, 255, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TermsAgreement = styled.div`
  color: rgba(0, 0, 0, 0.35);
  font-size: 12px;
`;

const CantParticipateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px; // less than the 100px height of the container. this pushes things up ever so slightly.

  font-weight: 500;
`;

const PetitionActionBar = ({
  setActionBarHeight,
  actionBarHeight,
  petitionInfo,
}) => {
  // we want the parent petition component to know the height of this actionBar component, so it can set it's bottom margin accordingly.
  const actionBarRef = useRef(null);
  useEffect(() => {
    // setting the total height, including the 1px border
    const heightWithBorder = actionBarRef.current.clientHeight + 1;
    setActionBarHeight(heightWithBorder);
  }, [actionBarRef]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stanceWriteLoading, setStanceWriteLoading] = useState(false);
  const [showConfirmationOverlay, setShowConfirmationOverlay] = useState(false);

  // get the selected voter info.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );

  // we want to display the name, and city.
  const voterName = `${selectedVoter?.first || ""} ${
    selectedVoter?.last || ""
  } ${selectedVoter?.suffix || ""}`;
  const voterStateLw = selectedVoter?.state || "";
  const voterState = voterStateLw.toUpperCase();
  const voterCity = (
    <>
      {selectedVoter?.city || ""}, {voterState}
    </>
  );
  const voterCongressionalDistrict = (
    <>
      {" "}
      <NoWrap>
        ({voterState}-{selectedVoter?.district || ""})
      </NoWrap>
    </>
  );

  // want to display the right stance for specific petition.
  const allStances = selectedVoter?.stances; // this may be null for a new voter.
  const voterStance = allStances
    ? allStances[petitionInfo.fireDbPath]?.currentStance?.stance || ""
    : ""; // null voterStance will be "" to allow for upperCase string function.
  const voterDocId = selectedVoter?.voterId;

  const writeStance = async (newStance) => {
    const writeStanceBackend = httpsCallable(fireFunctions, "writeStance");
    setStanceWriteLoading(true);
    await writeStanceBackend({
      stance: newStance,
      voterId: voterDocId,
      fireDbPetition: petitionInfo.fireDbPath, // so we write to this specific petition
    });
    const voterDisplayDocRef = doc(fireDb, "voterDisplay", voterDocId);
    const voterDisplayDoc = await getDoc(voterDisplayDocRef);
    if (voterDisplayDoc.exists()) {
      dispatch(
        setMatchedSelectedVoter({
          voterId: voterDisplayDoc.id,
          ...voterDisplayDoc.data(),
        }),
      );
    }
    setStanceWriteLoading(false);
  };

  let actionBarGuts = (
    <>
      <SignInInstructions>Sign in to voice your opinion:</SignInInstructions>
      <SignInButtonWrapper>
        <NavbarMobileButton
          text={"SIGN IN"}
          onClick={() => {
            navigate("/signin");
          }}
        />
      </SignInButtonWrapper>
    </>
  );

  if (selectedVoter) {
    let disallowPartMsg = "";
    if (petitionInfo.jurisdiction.jdn === JDN_ENUMS.STATE) {
      const petState = petitionInfo?.jurisdiction?.state;
      if (petState !== voterStateLw) {
        disallowPartMsg = `You cannot voice your opinion on this ${STATE_DICT[petState]?.fullName || ""}
          petition because you are registered to vote in ${STATE_DICT[voterStateLw].fullName || ""}.`;
      }
      // TODO: Will need to flesh out this logic when we add city petitions.
    } else if (petitionInfo.jurisdiction.jdn === JDN_ENUMS.CITY) {
      disallowPartMsg = "You cannot participate on city petitions";
    }

    if (disallowPartMsg) {
      actionBarGuts = (
        <CantParticipateWrapper>{disallowPartMsg}</CantParticipateWrapper>
      );
    } else {
      actionBarGuts = (
        <>
          <SignatoryName>
            {voterName} of {voterCity}
            {petitionInfo?.jurisdiction?.jdn === JDN_ENUMS.NATIONAL &&
              voterCongressionalDistrict}
          </SignatoryName>
          <BoldDeclaration>
            I am in favor of this policy proposal:
          </BoldDeclaration>
          <ButtonWrapper>
            {stanceWriteLoading ? (
              <TailSpin color="black" height={30} width={30} />
            ) : (
              <>
                <PetitionButton
                  text={"YES"}
                  isActive={voterStance.toUpperCase() === "YES"}
                  onClick={() => {
                    writeStance("YES").then(() => {
                      setShowConfirmationOverlay(true);
                    });
                  }}
                />
                <ButtonSpace />
                <PetitionButton
                  text={"NO"}
                  isActive={voterStance.toUpperCase() === "NO"}
                  onClick={() => {
                    writeStance("NO").then(() => {
                      setShowConfirmationOverlay(true);
                    });
                  }}
                />
              </>
            )}
          </ButtonWrapper>
          {!stanceWriteLoading && (
            <TermsAgreement>
              By participating, you agree to our{" "}
              <Link
                to="/about/policies"
                target="_blank"
                style={{ color: "rgba(0, 0, 0, 0.35)" }}
              >
                policies
              </Link>
              .
            </TermsAgreement>
          )}
        </>
      );
    }
  }

  return (
    <>
      {stanceWriteLoading && (
        <DisableInteraction $actionBarHeight={actionBarHeight} />
      )}
      {showConfirmationOverlay && (
        <ConfirmationOverlay
          closeModal={() => {
            setShowConfirmationOverlay(false);
          }}
          petitionName={petitionInfo.uiTitle}
        />
      )}
      <StickyBottom ref={actionBarRef}>{actionBarGuts}</StickyBottom>
    </>
  );
};

PetitionActionBar.propTypes = {
  setActionBarHeight: PropTypes.func,
  actionBarHeight: PropTypes.number,
  petitionInfo: PropTypes.object,
};

export default PetitionActionBar;
